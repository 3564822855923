<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">Новые Бонусы</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <div v-if="managers" class="pb-2" :class="mode ? 'text__day2' : 'text__night2'">
            <b>Ma'sul Xodimlar: </b>
            <span v-for="manager in managers" :key="manager.id">{{manager.name}} {{manager.last_name}}, </span>
          </div>
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="staff_id">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Сотрудники</span
                  >
                  <select-staff
                    :id="form.staff_id"
                    v-model="form.staff_id"
                  ></select-staff>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="penatly_reason_id">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Причина</span
                  >
                  <select-bonuse-reason
                    :type="'bonuses'"
                    :id="form.reason_id"
                    v-model="form.reason_id"
                  ></select-bonuse-reason>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="amount">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Сумма санкции</span
                  >
                  <crm-input
                    placeholder="Сумма санкции"
                    :inputValue="form.amount"
                    v-model="form.amount"
                    :type="'number'"
                    size="medium"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item prop="name">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Название санкции</span
                  >
                  <crm-input
                    placeholder="Название санкции"
                    :inputValue="form.name"
                    v-model="form.name"                   
                    size="medium"
                  ></crm-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item>
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Комментарий</span>
                  <crm-input
                    placeholder="Комментарий"
                    :inputValue="form.comment"                    
                    v-model="form.comment"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></crm-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import selectStaff from "@/components/filters/inventory/select-staff";
import selectBonuseReason from "@/components/filters/inventory/select-bonuse-reason";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
export default {
  mixins: [drawer, form],
  name: "BonusController",
  components: { selectStaff, selectBonuseReason },
  data() {
    return {
      managers:null,
      form: {
        loadCard: false,
        amount:0
      },
      penalty_reason:null,
    };
  },
  watch:{
    "form.staff_id": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.getSalary();
        }
      },
      deep: true,
    },
    "form.reason_id": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.getReason();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      rules: "bonus/rules",
      model: "bonus/model",
      columns: "bonus/columns",
      penatlyReason: "penaltyReasons/model",
      staffSalary: "penaltyReasons/staff_salary",
      mode: "MODE"
    }),
  },
  
  methods: {
    ...mapActions({
      getPenatlyReason: "penaltyReasons/show",
      getStaffSalary: "penaltyReasons/gatStaffSalary",
      save: "bonus/store",
    }),
    getReason() {
      if (!this.loadCard) {
        this.loadCard = true;
         this.getPenatlyReason(this.form.reason_id)
          .then((res) => {
            this.penalty_reason = res.data.result.data.penalty_reason;
            this.managers = res.data.result.data.penalty_reason.managers
            if (this.penalty_reason) {
              this.calculateSalary();              
            }
            // this.loadCard = false;
          })
          .catch((err) => {
            this.loadCard = false;
            this.$alert(err);
          });
      }
    },
     getSalary() {
      if (this.form.staff_id) {
         this.getStaffSalary(this.form.staff_id)
          .then((res) => {
            if (this.staffSalary) {
              this.calculateSalary();              
            }
            // console.log('nurillo',this.staffSalary);
            // if (this.form.salary) {
            //   this.form.salary_id = this.form.salary.id;
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    calculateSalary() {
      if (this.staffSalary && this.penalty_reason) {
          console.log('nurillo',this.penalty_reason);
          console.log('nurillo',this.staffSalary);
          if (this.penalty_reason.penalty_type=="procent") {
            this.form.amount = (this.staffSalary*this.penalty_reason.value)/100;
          }
          else{
            this.form.amount = (this.penalty_reason.value);
          }
      }
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
